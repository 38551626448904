<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.LANGCODE" langlabel="langcode" />
        <m-input v-model="form.LANGNAME" langlabel="langname" />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <strong>{{ $t("form.langfield") }}</strong>
          </div>
          <div class="col-4">
            <strong>{{ $t("form.description") }}</strong>
          </div>
          <div class="col-4">
            <strong>{{ $t("form.code") }}</strong>
          </div>
        </div>

        <div class="row" v-for="value in langValues" :key="value.FIELDID">
          <div class="col-4">
            <m-input nolabel v-model.lazy="value.VALUE" />
          </div>
          <div class="col-4">{{ value.DESC }}</div>
          <div class="col-4">{{ value.FIELDCODE }}</div>
        </div>
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  beforeMount() {
    this.getLangField();
  },
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getLang(this.ID);
    }
  },
  data() {
    return {
      ID: null,
      form: {
        LANGCODE: null,
        LANGNAME: null,
        ISACTIVE: 1,
      },
      loadingShow: false,
      loadingMsg: null,
      langFields: [],

      langValues: [],
    };
  },
  methods: {
    getLangField() {
      api.getLangField().then((res) => {
        if (res.data.status) {
          this.langFields = res.data.data.rows;
          this.langValues = this.langFields.map((item) => {
            return {
              VALUEID: null,
              FIELDID: item.ID,
              DESC: item.DESCRIPTION,
              FIELDCODE: item.FIELDCODE,
              VALUE: null,
            };
          });
        }
      });
    },
    save() {
      this.loadingMsg = "Kayıt ediliyor...";
      this.loadingShow = true;
      api
        .saveLang(
          {
            ...this.form,
            langvalues: this.langValues.map((item) => ({
              VALUEID: item.VALUEID,
              FIELDID: item.FIELDID,
              VALUE: item.VALUE,
            })),
          },
          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getLang(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getLangOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const { LANGCODE, LANGNAME, ISACTIVE, langvalues } = res.data.data;

            this.form = { LANGCODE, LANGNAME, ISACTIVE };

            langvalues.forEach((item) => {
              const findedIndex = this.langValues.findIndex(
                (lv) => lv.FIELDID == item.LANGFIELDID
              );
              this.langValues[findedIndex].VALUE = item.LANGVALUE || null;
              this.langValues[findedIndex].VALUEID = item.ID;
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteLang(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>
