<template>
  <div class="row">
    <div class="col-12 mt-3 text-center">
      <img src="../../assets/logo.png" style="height: 65px" />
    </div>
    <div class="col-12 mt-3" v-if="getPerms('cargolist')">
      <b-card-group deck>
        <b-card
          bg-variant="primary"
          text-variant="white"
          :header="$t('info.totalcargocount')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ total }}</span>
          </b-card-text>
        </b-card>

        <b-card
          bg-variant="secondary"
          text-variant="white"
          :header="$t('info.totalincoimingcargocount')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ coming }}</span>
          </b-card-text>
        </b-card>

        <b-card
          bg-variant="success"
          text-variant="white"
          :header="$t('info.totaloutgoingcargocount')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ sending }}</span>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
    <div class="col-12 mt-3" v-if="getPerms('cargolist')">
      <b-card-group deck>
        <b-card
          bg-variant="info"
          text-variant="white"
          :header="$t('info.waitedcargo')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ others }}</span>
          </b-card-text>
        </b-card>

        <b-card
          bg-variant="warning"
          text-variant="white"
          :header="$t('info.totaldeliveredcargocount')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ delivery }}</span>
          </b-card-text>
        </b-card>

        <b-card
          bg-variant="danger"
          text-variant="white"
          :header="$t('info.totalreturnedcargocount')"
          class="text-center"
        >
          <b-card-text class="text-center">
            <span style="font-size: 32px">{{ returns }}</span>
          </b-card-text>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
  },
  data() {
    return {
      total: 0,
      coming: 0,
      sending: 0,
      returns: 0,
      delivery: 0,
      others: 0,
    };
  },

  mounted() {
    this.getCargoStat();

    setInterval(this.getCargoStat, 1000 * 60 * 20);
  },

  methods: {
    getCargoStat() {
      if (this.getPerms("cargolist")) {
        api.getCargoStat().then((res) => {
          if (res.data.status) {
            this.total = res.data.data.total;
            this.coming = res.data.data.coming;
            this.sending = res.data.data.sending;
            this.delivery = res.data.data.delivery;
            this.returns = res.data.data.return;
            this.others = res.data.data.other;
          }
        });
      }
    },
  },
};
</script>

<style></style>
