<template>
  <div class="row">
    <template v-if="isBrokerage">
      <div class="col-12">
        <m-select :disabled="isAllDisabled" :options="options.brokerage" v-model="form.BROKERAGEID"
          langlabel="mediate" />
        <m-select :disabled="isAllDisabled" :options="options.brokerageagent" v-model="form.BROKERAGEAGENTID"
          langlabel="mediateagentname" />
      </div>
    </template>

    <template v-if="!isBrokerage">
      <div class="col-9">
        <multiselect :disabled="isAllDisabled" v-model="selected.present" :options="lookup.presentData"
          :placeholder="$t('form.presentsearch')" label="text" track-by="value" :limit="3"
          @search-change="searchPresent" :selectLabel="$t('multiselect.pressentertoselect')"
          :selectedLabel="$t('multiselect.selected')" :deselectLabel="$t('multiselect.pressentertoselect')">
          <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
          <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
        </multiselect>
        <br />
      </div>
      <div class="col-3">
        <b-btn variant="warning" :disabled="isAllDisabled" @click="clearForm">{{
            $t("buton.clear")
        }}</b-btn>
      </div>
      <template v-if="isUseParsedName">
        <div class="col-4">
          <m-input :disabled="isAllDisabled" :placeholder="$t('form.firstname')" :readonly="isSelectedForm"
            v-model="form.FIRSTNAME" nolabel />
        </div>
        <div class="col-4">
          <m-input :disabled="isAllDisabled" :readonly="isSelectedForm" :placeholder="$t('form.secondname')"
            v-model="form.SECONDNAME" nolabel />
        </div>
        <div class="col-4">
          <m-input :disabled="isAllDisabled" :placeholder="$t('form.lastname')" :readonly="isSelectedForm"
            v-model="form.LASTNAME" nolabel />
        </div>
      </template>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :placeholder="$t('form.firmname')" :readonly="isSelectedForm"
          v-model="form.FIRMNAME" nolabel />
      </div>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :readonly="isSelectedForm || isUseParsedName"
          :placeholder="$t('form.presentname')" v-model="form.FULLNAME" nolabel />
      </div>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :readonly="isSelectedForm" :placeholder="$t('form.taxoffice')"
          v-model="form.TAXOFFICE" nolabel />
      </div>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :placeholder="$t('form.identityno')" v-model="form.IDENTITYNO" nolabel
          type="number" :readonly="isSelectedForm" />
      </div>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :placeholder="$t('form.phone')" v-model="form.PHONE" nolabel type="number"
          :readonly="isSelectedForm" />
      </div>
      <div class="col-6">
        <m-input :disabled="isAllDisabled" :placeholder="$t('form.gsm')" :readonly="isSelectedForm" v-model="form.GSM"
          nolabel type="number" />
      </div>

      <div class="col-10">
        {{ $t("buton.addressselect") }}
        <m-select :disabled="isAllDisabled" nolabel v-model="form.ADDRESSID" :options="options.address" />
      </div>
      <div class="col-2 text-right">
        <br />

        <b-btn :disabled="isAllDisabled" size="sm" variant="success" @click="newAddress">
          {{ $t("buton.newrow") }}
        </b-btn>
      </div>

      <div v-show="useCountry" class="col-6">
        {{ $t("form.country") }}
        <m-select nolabel :disabled="isSelectedAddressForm || isAllDisabled" :options="options.country"
          v-model="form.COUNTRYID" />
      </div>
      <div v-if="useRegion" :class="{
        'col-6': useCountry && useRegion,
        'col-12': !useCountry && useRegion,
      }">
        {{ $t("form.region") }}
        <m-select nolabel :disabled="isSelectedAddressForm || isAllDisabled" :options="options.region"
          v-model="form.REGIONID" />
      </div>

      <div class="col-4">
        {{ $t("form.city") }}
        <m-select nolabel :disabled="isSelectedAddressForm || isAllDisabled" :options="options.city"
          v-model="form.CITYID" />
      </div>
      <div class="col-4">
        {{ $t("form.county") }}

        <m-select nolabel :disabled="isSelectedAddressForm || isAllDisabled" :options="options.county"
          v-model="form.COUNTYID" />
      </div>
      <div class="col-4">
        {{ $t("form.neighborhood") }}

        <m-select nolabel :disabled="isSelectedAddressForm || isAllDisabled" :options="options.town"
          v-model="form.TOWNID" />
      </div>
      <div class="col-12">
        <m-textarea :disabled="isAllDisabled" nolabel :readonly="isSelectedAddressForm" v-model="form.ADDRESS"
          :placeholder="$t('form.address')" />
      </div>
    </template>
  </div>
</template>

<script>
import swal from "sweetalert";
import api from "../../api";
import config from "../../config";

const conv = (str) => {
  if (!str) return str;
  let text = str.replace(/Ğ/g, "G")
    .replace(/ğ/g, "g")
    .replace(/Ü/g, "U")
    .replace(/ü/g, "u")
    .replace(/Ş/g, "S")
    .replace(/ş/g, "s")
    .replace(/İ/g, "I")
    .replace(/ı/g, "i")
    .replace(/Ö/g, "O")
    .replace(/ö/g, "o")
    .replace(/Ç/g, "C")
    .replace(/ç/g, "c");
  text = text.trim();
  const texts = text.split(' ');
  return texts[0].toUpperCase();
}

export default {
  props: {
    isBrokerage: {
      type: Boolean,
      default: () => false,
    },
    errorLabel: {
      type: String,
      default: () => "Gönderici",
    },
  },
  computed: {
    isUseParsedName() {
      return config.USE_PARSED_NAME;
    },
    useCountry() {
      return config.USE_COUNTRY;
    },
    useRegion() {
      return config.USE_REGION;
    },
  },
  mounted() {
    this.options.address[0].text = this.$t("form.newaddress");
    this.getBrokerage();

    if (this.useCountry) {
      this.getCountry();
    } else if (this.useRegion) {
      this.getRegion();
    } else {
      this.getCity();
    }
  },
  data() {
    return {
      isAllDisabled: false,
      isSelectedForm: false,
      isSelectedAddressForm: false,
      form: {
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        PRESENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,
      },

      options: {
        brokerage: [],
        brokerageagent: [],
        address: [
          {
            text: "Yeni Adres",
            value: null,
          },
        ],
        country: [],
        region: [],
        city: [],
        county: [],
        town: [],
      },
      lookup: {
        presentData: [],
        addressData: [],
      },

      selected: {
        present: null,
      },

      sync: {
        address: null,
        city: null,
        county: null,
        neighboorhood: null,
        isSync: false,
        cityFounded: false,
        countyFounded: false,
        isRunned: false,
      }
    };
  },
  methods: {
    setFormBrokerAgeData(isBrokerAge, brokerAgeId, brokerAgeAgentId) {
      this.form.ISBROKERAGEPRESENT = isBrokerAge;
      this.form.BROKERAGEID = brokerAgeId;
      this.form.BROKERAGEAGENTID = brokerAgeAgentId;
    },
    setFormData(data) {
      this.form = { ...data };
    },
    setAddressSync(city, county, address) {
      this.sync.isSync = true;
      this.sync.isRunned = false;
      this.sync.city = conv(city);
      this.sync.county = conv(county);
      this.sync.address = address;

      if (this.options.city.length) {
        this.sync.isRunned = true;
        this.addressSync();
      }
    },
    addressSync() {
      if (this.sync.isSync) {
        this.options.city.forEach(city => {
          if (conv(city.text) == this.sync.city) {
            this.form.CITYID = city.value;
            this.sync.cityFounded = true;
          }
        });

      }
    },

    setPresent(presentId, isUseEmit = false, isAllDisabled = false) {
      api.getPresentOne(presentId).then((res) => {
        if (res.data.status) {
          this.form.PRESENTID = presentId;
          this.form.FIRSTNAME = res.data.data.FIRSTNAME;
          this.form.SECONDNAME = res.data.data.SECONDNAME;
          this.form.LASTNAME = res.data.data.LASTNAME;
          this.form.FIRMNAME = res.data.data.FIRMNAME;
          this.form.FULLNAME = res.data.data.FULLNAME;
          this.form.IDENTITYNO = res.data.data.IDENTITYNO;
          this.form.TAXOFFICE = res.data.data.TAXOFFICE;
          this.form.PHONE = res.data.data.PHONE;
          this.form.GSM = res.data.data.GSM;

          if (isUseEmit) {
            this.$emit("changePresent", { id: presentId, type: "present" });
          }

          if (isAllDisabled) {
            this.isAllDisabled = isAllDisabled;
          }
        }
      });
    },
    setPresentAddress(presentId, addressId) {
      this.getAddress(presentId).then((res) => {
        this.form.ADDRESSID = addressId;
      });
    },
    getFormPresent() {
      if (this.isBrokerage) {
        this.form.ISBROKERAGEPRESENT = 1;
      }

      return new Promise((resolve, reject) => {
        if (this.form.ISBROKERAGEPRESENT) {
          if (!this.form.BROKERAGEID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.mediatenotnull"),
              "error"
            );
            reject();
            return;
          }
          if (!this.form.BROKERAGEAGENTID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.mediateagentnotnull"),
              "error"
            );
            reject();
            return;
          }
        } else {
          if (!this.form.PRESENTID) {
            if (this.isUseParsedName && !this.form.FIRSTNAME) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.firstname"),
                "error"
              );
              reject();
              return;
            }

            if (this.isUseParsedName && !this.form.LASTNAME) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.lastname"),
                "error"
              );
              reject();
              return;
            }

            if (!this.form.FULLNAME) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.presentname"),
                "error"
              );
              reject();
              return;
            }

            if (!this.form.IDENTITYNO) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.identitynonotnull"),
                "error"
              );
              reject();
              return;
            }
            /* Ukraynaya uyumluluk için kaldırıldı
            if (this.form.IDENTITYNO.length > 11) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " Vergi/TC no max 11 karakter olabilir!",
                "error"
              );
              reject();
              return;
            }
  */
            if (!this.form.GSM) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.gsmnotnull"),
                "error"
              );
              reject();
              return;
            }
          }

          if (!this.form.ADDRESSID) {
            if (this.useCountry) {
              if (!this.form.COUNTRYID) {
                swal(
                  this.$t("dialog.notice"),
                  this.errorLabel +
                  " " +
                  this.$t("validate.addresscountrynotnull"),
                  "error"
                );
                reject();
                return;
              }
            }

            if (this.useRegion) {
              if (!this.form.REGIONID) {
                swal(
                  this.$t("dialog.notice"),
                  this.errorLabel +
                  " " +
                  this.$t("validate.addressregionnotnull"),
                  "error"
                );
                reject();
                return;
              }
            }

            if (!this.form.CITYID) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.addresscitynotnull"),
                "error"
              );
              reject();
              return;
            }
            if (!this.form.COUNTYID) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel +
                " " +
                this.$t("validate.addresscountynotnull"),
                "error"
              );
              reject();
              return;
            }
            if (!this.form.TOWNID) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel +
                " " +
                this.$t("validate.addressneighborhoodnotnull"),
                "error"
              );
              reject();
              return;
            }
            if (!this.form.ADDRESS) {
              swal(
                this.$t("dialog.notice"),
                this.errorLabel + " " + this.$t("validate.addressnotnull"),
                "error"
              );
              reject();
              return;
            }
          }
        }
        resolve(this.form);
      });
    },
    clearForm() {
      this.$emit("clearForm");
      this.isSelectedForm = false;
      this.isSelectedAddressForm = false;
      this.form = {
        PRESENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,
      };

      this.options.address = [
        {
          text: this.$t("form.newaddress"),
          value: null,
        },
      ];
      this.lookup.addressData = [];
      this.lookup.presentData = [];
      this.selected.present = null;
    },
    newAddress() {
      if (
        this.options.address.length &&
        this.options.address[0].value != null
      ) {
        this.options.address.unshift({
          text: this.$t("form.newaddress"),
          value: null,
        });
      }
      this.isSelectedAddressForm = false;
      this.form.ADDRESSID = null;
      this.form.COUNTRYID = null;
      this.form.REGIONID = null;
      this.form.CITYID = null;
      this.form.TOWNID = null;
      this.form.COUNTYID = null;
      this.form.ADDRESS = null;
    },
    getAddress(id) {
      return api.getPresentAddressWithPresentId(id).then((res) => {
        if (res.data.status) {
          this.lookup.addressData = res.data.data.rows;
          this.form.ADDRESSID = this.lookup.addressData[0].ID;
          this.options.address = res.data.data.rows.map((item) => {
            return {
              text: item.ADDRESSNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    searchPresent(query) {
      if (query.length < 4) {
        return;
      }

      this.getPresentWithTerm(query).then((res) => {
        if (res.data.status && !res.data.msg) {
          this.lookup.presentData = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FIRMNAME ? item.FIRMNAME : ""} (${item.FULLNAME ? item.FULLNAME : ""
                }) [${item.IDENTITYNO ? item.IDENTITYNO : ""}]`,
              value: item.ID,
            };
          });
        }
      });
      //}, 500);
    },
    getPresentWithTerm(str, allPresent = 0) {
      let isBrokeragePresent = this.form.ISBROKERAGES;
      if (allPresent) {
        isBrokeragePresent = -1;
      }
      return api.getPresentWithTerm(str, isBrokeragePresent);
    },

    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getBrokerageAgentWithBrokerageId(id) {
      api
        .getBrokerageAgentWidthBrokerageId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerageagent = res.data.data.rows.map((item) => {
              return { text: item.AGENTTITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCountry(id) {
      api
        .getRegionWidthCountryId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeRegion(id) {
      api
        .getCityWidthRegionId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCity(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });

            if (this.sync.isSync && this.sync.cityFounded) {
              this.options.county.forEach(county => {
                if (conv(county.text) == this.sync.county) {
                  this.form.COUNTYID = county.value;
                  this.sync.countyFounded = true;

                  if (config.USE_GMAP_API) {
                    api.getGMapData(this.sync.address).then(res => {
                      if (res.data.status) {
                        this.sync.neighboorhood = conv(res.data.data.NEIGHBORHOOD);
                      } else {
                        swal("Bilgi - Adres Çözümleme Sistemi", res.data.errMsg, "warning");
                      }
                    });
                  }
                }
              });

              this.$nextTick(() => {
                if (config.USE_GMAP_API) {
                  api.getGMapData(this.sync.address).then(res => {
                    if (res.data.status) {
                      const findedCounty = conv(res.data.data.COUNTY);
                      this.options.county.forEach(county => {
                        if (conv(county.text) == findedCounty) {
                          this.form.COUNTYID = county.value;
                          this.sync.countyFounded = true;
                        }
                      });

                      this.sync.neighboorhood = conv(res.data.data.NEIGHBORHOOD);
                    } else {
                      swal("Bilgi - Adres Çözümleme Sistemi", res.data.errMsg, "warning");
                    }
                  });
                }
              })
            }
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCounty(id) {
      api
        .getTownWidthCountyId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.town = res.data.data.rows.map((item) => {
              return { text: item.TOWNNAME, value: item.ID };
            });

            if (this.sync.isSync && this.sync.cityFounded && this.sync.neighboorhood) {
              this.options.town.forEach(town => {
                if (conv(town.text) == this.sync.neighboorhood) {
                  this.form.TOWNID = town.value;
                }
              });
            }
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountry() {
      api
        .getCountry()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.country = res.data.data.rows.map((item) => {
              return { text: item.COUNTRYNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getRegion() {
      api
        .getRegion()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });

            if (this.sync.isSync && !this.sync.isRunned) {
              this.sync.isRunned = true;
              this.addressSync();
            }
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    concatName() {
      this.form.FULLNAME = [
        this.form.FIRSTNAME,
        this.form.SECONDNAME,
        this.form.LASTNAME,
      ]
        .filter((item) => item)
        .join(" ");
    },
  },
  watch: {
    "form.BROKERAGEID"(id) {
      if (id != null && id != 0 && id != "" && this.isBrokerage) {
        this.$emit("changePresent", { id: id, type: "brokerage" });
        this.getBrokerageAgentWithBrokerageId(id);
      }
    },
    "selected.present"(val) {
      if (val) {
        this.$emit("changePresent", { id: val.ID, type: "present" });
        this.isSelectedForm = true;
        this.form.PRESENTID = val.ID;
        this.form.FIRSTNAME = val.FIRSTNAME;
        this.form.SECONDNAME = val.SECONDNAME;
        this.form.LASTNAME = val.LASTNAME;
        this.form.FIRMNAME = val.FIRMNAME;
        this.form.FULLNAME = val.FULLNAME;
        this.form.TAXOFFICE = val.TAXOFFICE;
        this.form.IDENTITYNO = val.IDENTITYNO;
        this.form.GSM = val.GSM;
        this.form.PHONE = val.PHONE;

        this.getAddress(val.ID);
      }
    },
    "form.ADDRESSID"(id) {
      if (id) {
        this.isSelectedAddressForm = true;
        const index = this.lookup.addressData.findIndex((item) => {
          return item.ID == id;
        });

        const address = this.lookup.addressData[index];

        this.form.COUNTRYID = address.COUNTRYID;
        this.form.REGIONID = address.REGIONID;
        this.form.CITYID = address.CITYID;
        this.form.COUNTYID = address.COUNTYID;
        this.form.TOWNID = address.TOWNID;
        this.form.ADDRESS = address.ADDRESS;
      }
    },
    "form.COUNTRYID"(val) {
      if (this.useCountry) {
        if (val) {
          this.changeCountry(val);
        }
      }
    },
    "form.REGIONID"(val) {
      if (this.useRegion) {
        if (val) {
          this.changeRegion(val);
        }
      }
    },
    "form.CITYID"(val) {
      if (val) {
        this.changeCity(val);
      }

      this.$emit("changeLine", val);
    },
    "form.COUNTYID"(val) {
      if (val) {
        this.changeCounty(val);
      }
    },
    "form.TOWNID"(val) {
      this.$emit("changeReseller", val);
    },

    "form.FIRSTNAME"() {
      this.concatName();
    },
    "form.SECONDNAME"() {
      this.concatName();
    },
    "form.LASTNAME"() {
      this.concatName();
    },
  },
};
</script>

<style>
</style>
