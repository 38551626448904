<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-select
          :options="options.role"
          v-model="form.ROLEID"
          :validate="$v.form.ROLEID"
          langlabel="role"
        />

        <m-select
          :options="options.reseller"
          v-model="form.RESELLERID"
          :validate="$v.form.RESELLERID"
          langlabel="agent"
        />
        <m-input
          v-model="form.IDENTITYNO"
          :validate="$v.form.IDENTITYNO"
          langlabel="identityno"
        />
        <m-input
          v-model="form.FIRSTNAME"
          :validate="$v.form.FIRSTNAME"
          langlabel="name"
        />
        <m-input
          v-model="form.LASTNAME"
          :validate="$v.form.LASTNAME"
          langlabel="surname"
        />
        <m-input
          v-model="form.USERNAME"
          :validate="$v.form.USERNAME"
          langlabel="username"
        />
        <m-input
          v-model="form.PASSWORD"
          :validate="$v.form.PASSWORD"
          langlabel="password"
        />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
      </div>

      <div class="col-5">
        <m-select
          :options="options.city"
          v-model="form.CITYID"
          langlabel="city"
        />
        <m-select
          :options="options.county"
          v-model="form.COUNTYID"
          langlabel="İlçe"
        />
        <m-textarea v-model="form.ADDRESS" langlabel="address" />
        <m-input v-model="form.PHONE" langlabel="phone" />

        <m-input v-model="form.GSM" langlabel="gsm" />
        <m-input
          v-model="form.EMAIL"
          langlabel="email"
          :validate="$v.form.EMAIL"
        />
        <m-input v-model="form.BIRTHDATE" type="date" langlabel="birthdate" />

        <m-textarea v-model="form.DESCRIPTION" langlabel="description" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";

import {
  required,
  numeric,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getUser(this.ID);
    }
    this.getCity();
    this.getReseller();
    this.getRole();
  },
  data() {
    return {
      ID: null,
      form: {
        ROLEID: null,
        RESELLERID: null,
        IDENTITYNO: null,
        FIRSTNAME: null,
        LASTNAME: null,
        USERNAME: null,
        PASSWORD: null,
        ISACTIVE: 1,
        CITYID: null,
        COUNTYID: null,
        ADDRESS: null,
        PHONE: null,
        GSM: null,
        EMAIL: null,
        BIRTHDATE: null,
        DESCRIPTION: null,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        city: [],
        county: [],
        role: [],
        reseller: [],
      },
    };
  },
  validations: {
    form: {
      FIRSTNAME: {
        required,
      },
      LASTNAME: {
        required,
      },
      USERNAME: {
        required,
      },
      PASSWORD: {
        required,
      },
      ROLEID: {
        required,
      },
      RESELLERID: {
        required,
      },
      EMAIL: {
        email,
      },
      IDENTITYNO: {
        numeric,
        maxLength: maxLength(11),
        minLength: minLength(11),
      },
    },
  },
  methods: {
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getRole() {
      api
        .getRole()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.role = res.data.data.rows.map((item) => {
              return { text: item.ROLENAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },

    getReseller() {
      api
        .getReseller()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.reseller = res.data.data.rows.map((item) => {
              return { text: item.RESELLERNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      this.$v.$touch();
      if (!this.$v.form.$invalid) {
        this.loadingMsg = this.$t("dialog.savingrecord");
        this.loadingShow = true;
        api
          .saveUser(this.form, this.ID)
          .then((res) => {
            if (res.data.status) {
              if (this.ID == res.data.data.ID) {
                swal(
                  this.$t("dialog.success"),
                  this.$t("dialog.successfullyupdatedrecord"),
                  "success"
                );
              } else {
                this.ID = res.data.data.ID;
                swal(
                  this.$t("dialog.success"),
                  this.$t("dialog.successfullysaverecord"),
                  "success"
                );
              }
            } else {
              swal(
                this.$t("dialog.notice"),
                this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                "error"
              );
            }
          })
          .catch((err) => {
            swal(
              this.$t("dialog.systemerror"),
              this.$t("dialog.anerrorhasccurred") + err.toString(),
              "error"
            );
          })
          .finally(() => {
            this.loadingShow = false;
          });
      }
    },
    getUser(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getUserOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              ROLEID,
              RESELLERID,
              IDENTITYNO,
              FIRSTNAME,
              LASTNAME,
              USERNAME,
              PASSWORD,
              ISACTIVE,
              CITYID,
              COUNTYID,
              ADDRESS,
              PHONE,
              GSM,
              EMAIL,
              BIRTHDATE,
              DESCRIPTION,
            } = res.data.data;
            this.form = {
              ROLEID,
              RESELLERID,
              IDENTITYNO,
              FIRSTNAME,
              LASTNAME,
              USERNAME,
              PASSWORD,
              ISACTIVE,
              CITYID,
              COUNTYID,
              ADDRESS,
              PHONE,
              GSM,
              EMAIL,
              BIRTHDATE,
              DESCRIPTION,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteUser(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
  },
};
</script>

<style></style>
