<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('tab.global')" active>
        <div class="row">
          <div class="col-5">
            <m-input v-model="form.PACKAGENAME" langlabel="packagename" />
            <m-input
              v-model="form.PRICE"
              @keyup.native="calcAllPrice"
              type="number"
              langlabel="pricenontax"
            />
            <m-input
              v-model="form.RETURNPRICE"
              type="number"
              langlabel="returnpricenontax"
            />
            <m-input
              v-model="form.TAXRATE"
              @keyup.native="calcAllPrice"
              type="number"
              langlabel="taxrate"
            />
            <m-input
              v-model="form.TAXAMOUNT"
              readonly
              type="number"
              langlabel="taxamount"
            />
            <m-input
              v-model="form.AMOUNT"
              readonly
              type="number"
              langlabel="amount"
            />
            <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
            <m-yes-no
              v-model="form.ISREQUIREDDESI"
              langlabel="isrequireddeci"
            />
            <m-yes-no
              v-model="form.ISALLSHOWED"
              label="Tüm Şubeler Görebilir"
            />
          </div>
        </div>
      </b-tab>
      <b-tab :title="$t('tab.deci')">
        <div class="row">
          <div class="col-12"><h5>Değişken Desi Tanımı</h5></div>

          <div class="col-12">
            <b-btn
              variant="success"
              size="sm"
              style="float: right"
              @click="addDesi"
              >{{ $t("buton.newrow") }}</b-btn
            >
          </div>

          <div class="col-12">
            <b-table-simple>
              <b-thead>
                <tr>
                  <th style="width: 10%; color: white !important">
                    {{ $t("table.decistart") }}
                  </th>
                  <th style="width: 10%; color: white !important">
                    {{ $t("table.deciend") }}
                  </th>
                  <th style="width: 15%; color: white !important">
                    {{ $t("table.amountnontax") }}
                  </th>
                  <th
                    style="width: 15%; color: white !important"
                    v-if="USE_REGION_PRICE"
                  >
                    {{ $t("table.cityamountnontax") }}
                  </th>
                  <th
                    style="width: 15%; color: white !important"
                    v-if="USE_REGION_PRICE"
                  >
                    {{ $t("table.regionamountnontax") }}
                  </th>
                  <th style="width: 15%; color: white !important">
                    {{ $t("table.returnamountnontax") }}
                  </th>
                  <th style="width: 15%; color: white !important">
                    {{ $t("table.taxrate") }}
                  </th>
                  <th style="width: 5%; color: white !important">#</th>
                </tr>
              </b-thead>

              <b-tbody>
                <tr v-for="(ds, desiIndex) in desi" :key="desiIndex">
                  <td>
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESISTART"
                    />
                  </td>
                  <td>
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESIEND"
                    />
                  </td>
                  <td>
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESIPRICE"
                    />
                  </td>
                  <td v-if="USE_REGION_PRICE">
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESICITYPRICE"
                    />
                  </td>
                  <td v-if="USE_REGION_PRICE">
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESIREGIONPRICE"
                    />
                  </td>
                  <td>
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESIRETURNPRICE"
                    />
                  </td>
                  <td>
                    <m-input
                      nolabel
                      type="number"
                      v-model.lazy="desi[desiIndex].DESITAXRATE"
                    />
                  </td>
                  <td>
                    <b-btn variant="danger" @click="removeDesi(desiIndex)">{{
                      $t("buton.removerow")
                    }}</b-btn>
                  </td>
                </tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div class="col-12">
            <h5>Dinamik Desi Tanımı</h5>
            <small
              >Pakette tanımlanan desiler haricinde ilave bir desi için
              eklenecek fiyat.</small
            >
            <br />
            <input type="checkbox" v-model="form.DYNAMICDESIUSE" /> Dinamik Desi
            Kullan
          </div>
          <template v-if="form.DYNAMICDESIUSE">
            <div class="col-4">
              Başlangıç Desisi
              <m-input nolabel type="number" v-model="form.DYNAMICDESISTART" />
            </div>

            <div class="col-4">
              Fiyat
              <m-input nolabel type="number" v-model="form.DYNAMICDESIPRICE" />
            </div>

            <div class="col-4">
              Her Desi İçin Tutar
              <m-input
                nolabel
                type="number"
                v-model="form.DYNAMICDESIPERPRICE"
              />
            </div>
          </template>
        </div>
      </b-tab>
    </b-tabs>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
import config from "../../config";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getPackage(this.ID);
      this.getDesi(this.ID);
    }
  },
  computed: {
    USE_REGION_PRICE() {
      return config.USE_REGION_PRICE;
    },
  },
  data() {
    return {
      ID: null,
      desi: [],
      form: {
        PACKAGENAME: null,
        PRICE: null,
        RETURNPRICE: null,
        TAXRATE: null,
        TAXAMOUNT: null,
        AMOUNT: null,
        ISACTIVE: 1,
        ISREQUIREDDESI: 1,
        ISALLSHOWED: 1,
        DYNAMICDESIUSE: 0,
        DYNAMICDESISTART: null,
        DYNAMICDESIPRICE: null,
        DYNAMICDESIPERPRICE: null,
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    addDesi() {
      this.desi.push({
        ID: null,
        DESISTART: null,
        DESIEND: null,
        DESIPRICE: null,
        DESICITYPRICE: null,
        DESIREGIONPRICE: null,
        DESIRETURNPRICE: null,
        DESITAXRATE: null,
      });
    },
    getDesi(id) {
      api.getPackageDesi(id).then((res) => {
        if (res.data.status) {
          this.desi = res.data.data.map((item, index) => {
            return {
              ID: item.ID,
              DESISTART: item.DESISTART,
              DESIEND: item.DESIEND,
              DESIPRICE: toTrFromNumber(item.DESIPRICE),
              DESICITYPRICE: toTrFromNumber(item.DESICITYPRICE),
              DESIREGIONPRICE: toTrFromNumber(item.DESIREGIONPRICE),
              DESIRETURNPRICE: toTrFromNumber(item.DESIRETURNPRICE),
              DESITAXRATE: item.DESITAXRATE,
            };
          });
        }
      });
    },
    removeDesi(index) {
      swal({
        title: this.$t("dialog.notice"),
        text: "Silmek istediğinize emin misiniz? Bu işlemi geri alamazsınız!",
        dangerMode: true,
        buttons: [true, "Ok"],
      }).then((res) => {
        if (res) {
          this.loadingMsg = "Siliniyor...";
          this.loadingShow = true;
          api
            .removePackageDesi(this.desi[index].ID)
            .then((res) => {
              swal(this.$t("dialog.success"), "Başarıyla silindi.", "success");
              this.desi.splice(index, 1);
            })
            .catch((err) => {})
            .finally(() => {
              this.loadingMsg = this.$t("dialog.fetchingdata");
              this.loadingShow = false;
            });
        }
      });
    },
    calcAllPrice() {
      if (
        toNumberFromTr(this.form.PRICE) &&
        toNumberFromTr(this.form.TAXRATE)
      ) {
        this.form.TAXAMOUNT = toTrFromNumber(
          (toNumberFromTr(this.form.PRICE) *
            toNumberFromTr(this.form.TAXRATE)) /
            100
        );
        this.form.AMOUNT = toTrFromNumber(
          toNumberFromTr(this.form.TAXAMOUNT) + toNumberFromTr(this.form.PRICE)
        );
      }
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .savePackage(
          {
            ...this.form,
            PRICE: toNumberFromTr(this.form.PRICE),
            RETURNPRICE: toNumberFromTr(this.form.RETURNPRICE),
            TAXRATE: toNumberFromTr(this.form.TAXRATE),
            TAXAMOUNT: toNumberFromTr(this.form.TAXAMOUNT),
            AMOUNT: toNumberFromTr(this.form.AMOUNT),
            DYNAMICDESIPRICE: toNumberFromTr(this.form.DYNAMICDESIPRICE),
            DYNAMICDESIPERPRICE: toNumberFromTr(this.form.DYNAMICDESIPERPRICE),
          },
          this.desi.map((item) => {
            return {
              ...item,
              DESIPRICE: toNumberFromTr(item.DESIPRICE),
              DESICITYPRICE: toNumberFromTr(item.DESICITYPRICE),
              DESIREGIONPRICE: toNumberFromTr(item.DESIREGIONPRICE),
              DESIRETURNPRICE: toNumberFromTr(item.DESIRETURNPRICE),
            };
          }),
          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getPackage(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getPackageOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              PACKAGENAME,
              PRICE,
              RETURNPRICE,
              TAXRATE,
              TAXAMOUNT,
              AMOUNT,
              ISACTIVE,
              ISREQUIREDDESI,
              ISALLSHOWED,
              DYNAMICDESIUSE,
              DYNAMICDESISTART,
              DYNAMICDESIPRICE,
              DYNAMICDESIPERPRICE,
            } = res.data.data;
            this.form = {
              PACKAGENAME,
              RETURNPRICE: toTrFromNumber(RETURNPRICE),
              PRICE: toTrFromNumber(PRICE),
              TAXRATE: toTrFromNumber(TAXRATE),
              TAXAMOUNT: toTrFromNumber(TAXAMOUNT),
              AMOUNT: toTrFromNumber(AMOUNT),
              ISACTIVE,
              ISREQUIREDDESI,
              ISALLSHOWED,
              DYNAMICDESIUSE,
              DYNAMICDESISTART,
              DYNAMICDESIPRICE: toTrFromNumber(DYNAMICDESIPRICE),
              DYNAMICDESIPERPRICE: toTrFromNumber(DYNAMICDESIPERPRICE),
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deletePackage(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style></style>