<template>
  <m-app>
    <div class="m-container">
      <slot></slot>
    </div>
    <div class="m-button-area">
      <b-button-group style="float: right">
        <slot name="footer"></slot>
        <b-button
         v-if="showButtons"
          :disabled="formId < 1"
          @click="newForm"
          size="sm"
          squared
          variant="primary"
          >{{ $t("buton.newrecord") }} - F3</b-button
        >
        <b-button
         v-if="showButtons"
          :disabled="formId < 1"
          @click="remove"
          size="sm"
          squared
          variant="danger"
          >{{ $t("buton.remove") }} - F5</b-button
        >
        <b-button
         v-if="showButtons"
          @click="$emit('save')"
          size="sm"
          :disabled="buttonLoading"
          squared
          variant="success"
        >
          <b-spinner
            v-show="buttonLoading"
            variant="white"
            type="grow"
            small
            label="Spinning"
          ></b-spinner>

          {{ $t("buton.save") }} - F2
        </b-button>
      </b-button-group>
    </div>
    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </m-app>
</template>

<script>
import swal from "sweetalert";
export default {
  props: {
    buttonLoading: {
      type: Boolean,
      default: () => false,
    },
    formId: {
      type: [Number, String],
      default: () => 0,
    },
    loadingShow: {
      type: Boolean,
      default: () => false,
    },
    loadingMsg: {
      type: String,
      default: () => "Veri getiriliyor...",
    },
    showButtons: {
      type: Boolean,
      default: () => true,
    },
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      event.stopPropagation();

      if (event.key == "F2") {
        if (!this.buttonLoading) {
          this.$emit("save");
        }
        event.preventDefault();
        return false;
      }
      if (event.key == "F3") {
        this.newForm();
        event.preventDefault();
        return false;
      }
      if (event.key == "F5") {
        this.remove();
        event.preventDefault();
        return false;
      }
    });
  },
  methods: {
    newForm() {
      console.log(this.$route);
      console.log(this.formId);
      if (this.$route.params.id) {
        if (this.$route.name == "Cargo") {
          window.location.href = this.$route.path.replace(
            this.$route.params.id,
            "0"
          );
        } else {
          window.location.href = this.$route.path.replace(
            "/" + this.$route.params.id
          );
        }
      } else {
        window.location.href = window.location.href;
      }
      this.$emit("new-form");
    },
    remove() {
      if (intval(this.formId) > 0) {
        swal({
          title: this.$t("dialog.confirmremove"),
          text: this.$t("dialog.sureyouwanttoremove"),
          icon: "warning",
          buttons: [this.$t("dialog.cancel"), this.$t("dialog.yes")],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            this.$emit("remove");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.m-container {
  width: 100%;
  height: calc(100% - 34px);
  padding: 5px;
  overflow: auto;
}

.m-button-area {
  width: 100%;
  height: 34px;
  border-top: 2px solid #777;
}

.modal-content {
  color: #000;
}
</style>
