<template>
  <div class="row">
    <div class="col-12">
      İl
      <m-select
        class="search-form-field"
        v-model="search.CITYID"
        nolabel
        :placeholder="$t('search.city')"
      ></m-select>
    </div>

    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.COUNTYNAME"
        nolabel
        :placeholder="$t('search.countyname')"
      ></m-input>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        COUNTYNAME: null,
        CITYID: null,
      },
      options: {
        city: [],
      },
    };
  },
  mounted() {
    this.getCity();
  },
  methods: {
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },

    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        COUNTYNAME: null,
        CITYID: null,
      };
    },
  },
  watch: {
    "search.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
  },
};
</script>

<style></style>
