<template>
  <li :class="{ 'm-menu-divider': divider }">
    <a :class="{ 'm-sub-menu': submenu }" v-if="!divider">{{ title }}</a>
    <slot />
  </li>
</template>

<script>
  export default {
    props: {
      submenu: {
        type: Boolean,
        default: () => false,
      },
      title: {
        type: String,
        default: () => null,
      },
      divider: {
        type: Boolean,
        default: () => false,
      },
    },
  };
</script>
