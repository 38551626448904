<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-select
          :options="options.present"
          v-model="form.PRESENTID"
          langlabel="present"
        />

        <m-input v-model="form.ADDRESSNAME" langlabel="addressname" />
        <m-select
          :options="options.region"
          v-model="form.REGIONID"
          langlabel="region"
        />
        <m-select
          :options="options.city"
          v-model="form.CITYID"
          langlabel="city"
        />
        <m-select
          :options="options.county"
          v-model="form.COUNTYID"
          langlabel="county"
        />
        <m-select
          :options="options.town"
          v-model="form.TOWNID"
          langlabel="town"
        />

        <m-input v-model="form.BUILDINGNO" langlabel="buildingno" />
        <m-input v-model="form.BUILDINGBLOCKNO" langlabel="buildingblockno" />
        <m-input v-model="form.APARTMENTNO" langlabel="apertmentnumber" />

        <m-textarea
          v-if="!USE_CONCAT_ADDRESS"
          v-model="form.ADDRESS"
          langlabel="address"
        />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
import config from "../../config";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getPresentAddress(this.ID);
    }
    this.getPresent();
    if (this.USE_REGION) {
      this.getRegion();
    } else {
      this.getCity();
    }
  },
  computed: {
    USE_REGION() {
      return config.USE_REGION;
    },
    USE_CONCAT_ADDRESS() {
      return config.USE_REGION;
    },
  },
  data() {
    return {
      ID: null,
      form: {
        PRESENTID: null,
        ADDRESSNAME: null,
        REGIONID: null,
        CITYID: null,
        COUNTYID: null,
        TOWNID: null,
        ADDRESS: null,
        BUILDINGNO: null,
        BUILDINGBLOCKNO: null,
        APARTMENTNO: null,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        region: [],
        city: [],
        county: [],
        town: [],
        present: [],
      },
    };
  },
  methods: {
    concatAddress() {
      this.form.ADDRESS = [
        "буд. :",
        this.form.BUILDINGNO,
        "корпус :",
        this.form.BUILDINGBLOCKNO,
        "кв. :",
        this.form.APARTMENTNO,
      ]
        .filter((item) => item)
        .join(" ");
    },
    getPresent() {
      api
        .getPresent()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.present = res.data.data.rows.map((item) => {
              return {
                text: item.FIRMNAME + " - " + item.FULLNAME,
                value: item.ID,
              };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getRegion() {
      api
        .getRegion()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getTownWidthCountyId(id) {
      api
        .getTownWidthCountyId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.town = res.data.data.rows.map((item) => {
              return { text: item.TOWNNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCityWidthRegionId(id) {
      api
        .getCityWidthRegionId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .savePresentAddress(this.form, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getPresentAddress(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getPresentAddressOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              PRESENTID,
              ADDRESSNAME,
              CITYID,
              REGIONID,
              COUNTYID,
              TOWNID,
              ADDRESS,
              BUILDINGNO,
              BUILDINGBLOCKNO,
              APARTMENTNO,
            } = res.data.data;
            this.form = {
              PRESENTID,
              ADDRESSNAME,
              REGIONID,
              CITYID,
              COUNTYID,
              TOWNID,
              BUILDINGNO,
              BUILDINGBLOCKNO,
              APARTMENTNO,
              ADDRESS,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deletePresentAddress(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.REGIONID"(id) {
      this.getCityWidthRegionId(id);
    },
    "form.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
    "form.COUNTYID"(id) {
      this.getTownWidthCountyId(id);
    },

    "form.BUILDINGNO"() {
      if (this.USE_CONCAT_ADDRESS) {
        this.concatAddress();
      }
    },
    "form.BUILDINGBLOCKNO"() {
      if (this.USE_CONCAT_ADDRESS) {
        this.concatAddress();
      }
    },
    "form.APARTMENTNO"() {
      if (this.USE_CONCAT_ADDRESS) {
        this.concatAddress();
      }
    },
  },
};
</script>

<style></style>
