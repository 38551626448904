<template>
  <div class="row">
    <div class="col-12">
      {{ $t("search.agent") }}
      <m-select
        v-model="search.RESELLERID"
        class="search-form-field"
        :options="options.reseller"
        nolabel
      ></m-select>
    </div>

    <div class="col-12">
      {{ $t("search.barcode") }}
      <m-input
        v-model="search.BARCODE"
        class="search-form-field"
        nolabel
      ></m-input>
    </div>
    <div class="col-12" style="text-align: center">İşlem Tarihi</div>

    <div class="col-6">
      {{ $t("search.startdate") }}

      <m-input
        class="search-form-field"
        v-model="search.TRANSDATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>

    <div class="col-6">
      {{ $t("search.enddate") }}

      <m-input
        class="search-form-field"
        type="date"
        v-model="search.TRANSDATE[1]"
        nolabel
      ></m-input>
    </div>

    <div class="col-12" style="text-align: center">Kargo Tarihi</div>

    <div class="col-6">
      {{ $t("search.startdate") }}

      <m-input
        class="search-form-field"
        v-model="search.CARGODATE[0]"
        nolabel
        type="date"
      ></m-input>
    </div>
    <div class="col-6">
      {{ $t("search.enddate") }}

      <m-input
        class="search-form-field"
        type="date"
        v-model="search.CARGODATE[1]"
        nolabel
      ></m-input>
    </div>
  </div>
</template>

<script>
import api from "../../api";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getPerms"]),
  },
  data() {
    return {
      search: {
        RESELLERID: null,
        BARCODE: null,
        TRANSDATE: [null, null],
        CARGODATE: [null, null],
      },
      options: {
        reseller: [],
      },
    };
  },
  mounted() {
    this.getReseller();
  },
  methods: {
    getReseller() {
      api.getResellerWithAccount().then((res) => {
        if (res.data.status) {
          this.options.reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });
        }
      });
    },

    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        RESELLERID: null,
        BARCODE: null,
        TRANSDATE: [null, null],
        CARGODATE: [null, null],
      };
    },
  },
};
</script>

<style></style>
