<template>
  <div class="row">
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.FIRSTNAME"
        nolabel
        :placeholder="$t('form.firstname')"
      ></m-input>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.LASTNAME"
        nolabel
        :placeholder="$t('form.lastname')"
      ></m-input>
    </div>
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.ADDRESSNAME"
        nolabel
        :placeholder="$t('search.addressname')"
      ></m-input>
    </div>
    <div class="col-12">
      {{ $t("search.region") }}

      <m-select
        class="search-form-field"
        v-model="search.REGIONID"
        nolabel
        :options="options.region"
      ></m-select>
    </div>
    <div class="col-12">
      {{ $t("search.city") }}

      <m-select
        class="search-form-field"
        v-model="search.CITYID"
        nolabel
        :options="options.city"
      ></m-select>
    </div>
    <div class="col-12">
      {{ $t("search.county") }}

      <m-select
        class="search-form-field"
        v-model="search.COUNTYID"
        nolabel
        :options="options.county"
      ></m-select>
    </div>

    <div class="col-12">
      {{ $t("search.town") }}

      <m-select
        class="search-form-field"
        v-model="search.TOWNID"
        nolabel
        :options="options.town"
      ></m-select>
    </div>
  </div>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        ADDRESSNAME: null,
        REGIONID: null,
        TOWNID: null,
        CITYID: null,
        COUNTYID: null,
        FIRSTNAME: null,
        LASTNAME: null,
      },
      options: {
        region: [],
        city: [],
        county: [],
        town: [],
      },
    };
  },
  mounted() {
    this.getRegion();
  },
  methods: {
    getRegion() {
      api
        .getRegion()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCityWidthRegionId(id) {
      api
        .getCityWidthRegionId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getTownWidthCountyId(id) {
      api
        .getTownWidthCountyId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.town = res.data.data.rows.map((item) => {
              return { text: item.TOWNNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },

    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        ADDRESSNAME: null,
        REGIONID: null,
        TOWNID: null,
        CITYID: null,
        COUNTYID: null,
        FIRSTNAME: null,
        LASTNAME: null,
      };
    },
  },
  watch: {
    "search.REGIONID"(id) {
      this.getCityWidthRegionId(id);
    },
    "search.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
    "search.COUNTYID"(id) {
      this.getTownWidthCountyId(id);
    },
  },
};
</script>

<style></style>
