<template>
  <div style="width: 100%; height: calc(100vh - 120px)">
    <div ref="mapShowArea" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import config from "../../../config";
import api from "../../../api";
import Maps from "../../../libs/Maps";
export default {
  mounted() {
    if (config.USE_MAP_API) {
      this.gMap = new Maps();

      let intervalInitialized = null;
      let timeoutInitialized = null;
      const waitedInitializedTime = 10 * 1000;
      let waitedEnd = false;

      intervalInitialized = setInterval(() => {
        if (waitedEnd) {
          clearTimeout(timeoutInitialized);
          clearInterval(intervalInitialized);
        }
        if (this.gMap.isInitialized) {
          clearTimeout(timeoutInitialized);
          clearInterval(intervalInitialized);
          this.mapInit();
        }
      }, 1000);

      timeoutInitialized = setTimeout(() => {
        waitedEnd = true;
      }, waitedInitializedTime);
    }
  },
  data() {
    return {
      /**
       * @type Maps
       */
      gMap: null,

      polygons: [],
    };
  },
  methods: {
    mapInit() {
      api.getResellerPoly().then((res) => {
        if (res.data.status) {
          this.gMap.createMap(this.$refs.mapShowArea, res.data.data);
        }
      });
    },
  },
};
</script>

<style>
</style>