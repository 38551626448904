<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-input v-model="form.BROKERAGETITLE" langlabel="mediatename" />
        <m-input v-model="form.AUTHORTITLE" langlabel="mediateauthor" />
        <m-input v-model="form.TAXOFFICE" langlabel="taxoffice" />
        <m-input v-model="form.TAXNO" langlabel="taxno" />
        <m-input v-model="form.PHONE" langlabel="phone" />
        <m-input v-model="form.GSM" langlabel="gsm" />
        <m-input v-model="form.EMAIL" langlabel="email" />
        <m-input v-model="form.USERNAME" langlabel="username" />
        <m-input v-model="form.USERPASS" langlabel="password" />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
      </div>

      <div class="col-5">
        <m-select
          :options="options.city"
          v-model="form.CITYID"
          langlabel="city"
        />
        <m-select
          :options="options.county"
          v-model="form.COUNTYID"
          langlabel="county"
        />
        <m-textarea v-model="form.ADDRESS" langlabel="address" />
        <m-textarea v-model="form.DESCRIPTION" langlabel="description" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getBrokerage(this.ID);
    }
    this.getCity();
  },
  data() {
    return {
      ID: null,
      form: {
        BROKERAGETITLE: null,
        CITYID: null,
        COUNTYID: null,
        ADDRESS: null,
        ISACTIVE: 1,
        TAXOFFICE: null,
        TAXNO: null,
        PHONE: null,
        GSM: null,
        EMAIL: null,
        AUTHORTITLE: null,
        DESCRIPTION: null,
        USERNAME: null,
        USERPASS: null,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        city: [],
        county: [],
      },
    };
  },
  methods: {
    getCity() {
      api
        .getCity()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.city = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getCountyWidthCityId(id) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.county = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveBrokerage(this.form, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getBrokerage(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getBrokerageOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              BROKERAGETITLE,
              CITYID,
              COUNTYID,
              ADDRESS,
              ISACTIVE,
              TAXOFFICE,
              TAXNO,
              PHONE,
              GSM,
              EMAIL,
              AUTHORTITLE,
              DESCRIPTION,
              USERNAME,
              USERPASS,
            } = res.data.data;
            this.form = {
              BROKERAGETITLE,
              CITYID,
              COUNTYID,
              ADDRESS,
              ISACTIVE,
              TAXOFFICE,
              TAXNO,
              PHONE,
              GSM,
              EMAIL,
              AUTHORTITLE,
              DESCRIPTION,

              USERNAME,
              USERPASS,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteBrokerage(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.CITYID"(id) {
      this.getCountyWidthCityId(id);
    },
  },
};
</script>

<style></style>
