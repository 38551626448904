<template>
  <m-basic-form :showButtons="false">
    <div class="row">
      <div class="col-6">
        <div class="col-12">
          <h6>Farmazon Entegrasyon İşlemi</h6>
        </div>
        <div class="col-12">
          <m-select
            :options="options.brokerage"
            v-model="form.BROKERAGEID"
            label="Aracı"
          />
          <m-select
            :options="options.brokerageagent"
            v-model="form.BROKERAGEAGENTID"
            label="Aracı Şube"
          />
        </div>
        <div class="col-12">
          <m-input v-model="form.CARGOCODE" label="Farmazon Kargo Kodu" />
          <br />
        </div>
        <div class="col-12 mt-3">
          <b-button @click="send" size="sm" squared variant="info"
            >Sisteme Aktar</b-button
          >
        </div>
      </div>
    </div>
    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    this.getBrokerage();
  },
  data() {
    return {
      form: {
        CARGOCODE: null,
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
      },
      options: {
        brokerage: [],
        brokerageagent: [],
      },
      loadingShow: false,
      loadingMsg: null,
    };
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getBrokerageAgentWithBrokerageId(id) {
      api
        .getBrokerageAgentWidthBrokerageId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerageagent = res.data.data.rows.map((item) => {
              return { text: item.AGENTTITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    send() {
      if (this.form.CARGOCODE == null || this.form.CARGOCODE.length < 1) {
        swal(this.$t("dialog.notice"), "Kargo Kodu boş olamaz!", "error");
        return;
      }

      if (this.form.BROKERAGEID == null) {
        swal(this.$t("dialog.notice"), "Aracı boş olamaz!", "error");
        return;
      }

      if (this.form.BROKERAGEAGENTID == null) {
        swal(this.$t("dialog.notice"), "Aracı şubesi boş olamaz!", "error");
        return;
      }
      this.loadingMsg = "Veri sorgulanıyor...";
      this.loadingShow = true;
      api
        .farmazonTransfer(this.form)
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              "Başarıyla Entegrason Kargo Listesine aktarıldı!",
              "success"
            );
            this.form.CARGOCODE = null;
          } else {
            swal(
              this.$t("dialog.notice"),
              "Uyarı Metni: " + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal("Sistem Uyarısı", "Bir sorun oluştu:" + err.toString(), "error");
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.BROKERAGEID"(id) {
      this.getBrokerageAgentWithBrokerageId(id);
    },
  },
};
</script>

<style></style>
