<template>
  <b-form-group
    label-for="input-horizontal"
    :label-cols-sm="nolabel ? 0 : 4"
    :label="label"
    label-size="sm"
    label-align="right"
  >
    <b-input-group>
      <b-form-input
        :placeholder="placeholder"
        v-model="vValue"
        size="sm"
        small
        :type="type"
      ></b-form-input>
      <b-input-group-append>
        <b-button
          class="myc-custom-input-button"
          :variant="buttonVariant"
          v-html="buttonText"
          size="sm"
          @click="$emit('btn-click')"
          :disabled="buttonDisabled"
        ></b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    placeholder: String,
    value: String,
    label: String,
    type: {
      type: String,
      default: () => "text",
    },
    buttonText: {
      type: String,
      default: () => "...",
    },
    buttonVariant: {
      type: String,
      default: () => "primary",
    },
    buttonDisabled: {
      type: Boolean,
      default: () => false,
    },
    nolabel: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    vValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss">
.col-form-label-sm {
  font-size: inherit !important;
}
.form-control-sm {
  font-size: inherit !important;
}
.myc-custom-input-button {
  padding: 0 0.5rem !important;
}
</style>
