<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="panel">
      <div class="panel-top">
        <div class="row">
          <div class="col-5">
            <m-input
              v-model="form.MANIFESTBARCODE"
              langlabel="manifestbarcode"
              readonly
              :placeholder="$t('form.createdonsave')"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <m-input
              v-model="barcodeStr"
              @keyup.enter.prevent="searchBarcode"
              langlabel="barcode"
              :readonly="loadingBtn"
            />
          </div>
          <div class="col-1">
            <b-btn
              squared
              size="sm"
              :disabled="loadingBtn"
              variant="primary"
              @click="searchBarcode"
            >
              <b-spinner small v-show="loadingBtn"></b-spinner>
              <span v-show="!loadingBtn"> {{ $t("btn.addbarcode") }} </span>
            </b-btn>
          </div>
        </div>
      </div>
      <div class="panel-bottom">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-balham-dark"
          :components="components"
          :columnDefs="columnDefs"
          :rowData="rowData"
          rowSelection="multiple"
          :enableColResize="true"
          @rowDoubleClicked="(row) => this.$emit('rowDoubleClicked', row)"
          :gridOptions="gridOptions"
          ref="myGrid"
          :localeText="{ noRowsToShow: $t('grid.nodata') }"
        ></ag-grid-vue>
      </div>
    </div>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>

    <template slot="footer">
      <b-button
        @click="extractManifest"
        :disabled="ID == null"
        size="sm"
        squared
        variant="info"
        >{{ $t("buton.extractmanifest") }}</b-button
      >
      <b-button @click="printAction" size="sm" squared variant="warning"
        >Yazdır</b-button
      >
    </template>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";

import { AgGridVue } from "ag-grid-vue";
import "../../libs/GridValueFormatter";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getManifest(this.ID);
    }

    if ("cargoids" in this.$route.params) {
      if (this.$route.params.cargoids.length > 0) {
        this.getSelectedCargos(this.$route.params.cargoids.split("-"));
      }
    }
  },
  components: {
    AgGridVue,
  },
  computed: {
    printerUrl() {
      return `prdr://manifest_temp%20ID=${this.ID}%200`;
    },
  },
  data() {
    return {
      loadingBtn: false,
      barcodeStr: null,

      ID: null,
      form: {
        MANIFESTBARCODE: null,
        ISEXTRACT: "0",
      },
      loadingShow: false,
      loadingMsg: null,

      columnDefs: null,
      rowData: [],
      components: null,
      gridOptions: null,
    };
  },
  beforeMount() {
    this.gridOptions = {};
    api.getCols("cargo").then((res) => {
      this.columnDefs = res.data.map((item) => {
        if ("valueFormatter" in item) {
          switch (item.valueFormatter) {
            case "yesNo":
              item.valueFormatter = yesNo;
              break;
          }
        }
        //dil dönüşümü
        item.headerName = this.$t("list." + item.field);
        return item;
      });
    });
  },
  methods: {
    printAction() {
      this.$refs.printerBtn.click();
    },
    getSelectedCargos(ids) {
      this.loadingShow = true;
      api
        .getSelectedCargosForManifest({ ids })
        .then((res) => {
          if (res.data.status) {
            this.rowData = res.data.data;

            if (res.data.msg != null) {
              const wrapper = document.createElement("div");
              wrapper.style.color = "#000";
              wrapper.style.textAlign = "left";
              wrapper.style.fontSize = "12px";
              wrapper.innerHTML = res.data.msg;
              swal({
                title: this.$t("dialog.notice"),
                content: wrapper,
                type: "error",
              });
            } else {
              swal(
                this.$t("dialog.notice"),
                "Tüm kargolar başarılı şekilde aktarıldı. Lütfen kayıt ediniz!",
                "error"
              );
            }
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    searchBarcode() {
      if (this.barcodeStr == null || this.barcodeStr == "") {
        swal(this.$t("dialog.notice"), "Barkod boş olamaz", "error");
        return;
      }

      this.loadingBtn = true;

      const barcodes = this.barcodeStr.split(" ");
      const barcode = barcodes[0];

      const findedDuplicateBarcode = this.rowData.find((item) => {
        return item.BARCODE == barcode;
      });

      if (findedDuplicateBarcode) {
        swal(
          this.$t("dialog.notice"),
          "Bu barkodu daha önce eklediniz!",
          "error"
        );
        this.loadingBtn = false;
        return;
      }

      api
        .searchBarcodeForManifest({ barcode })
        .then((res) => {
          if (res.data.status) {
            this.rowData.push(res.data.data);
          } else {
            swal(this.$t("dialog.notice"), res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingBtn = false;
          this.barcodeStr = null;
        });
    },
    save() {
      if (intval(this.ID) > 0) {
        swal(
          this.$t("dialog.notice"),
          "Manifestoyu düzenleyemezsiniz!",
          "error"
        );
        return;
      }
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .saveManifest(
          {
            ...this.form,
            detail: this.rowData.map((item) => {
              return {
                ID: item.ID,
                BARCODE: item.BARCODE,
              };
            }),
          },
          this.ID
        )
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getManifest(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getManifestOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const { MANIFESTBARCODE, detail, ISEXTRACT } = res.data.data;
            this.rowData = detail;
            this.form = { MANIFESTBARCODE, ISEXTRACT };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    extractManifest() {
      if (this.form.ISEXTRACT == "1") {
        swal(
          this.$t("dialog.notice"),
          "Bu manifesto listesi daha önce çözüldü!",
          "error"
        );
        return;
      }

      this.loadingMsg = "Manifest listesi çözülüyor..";
      this.loadingShow = true;

      api
        .extractManifest({ manifestId: this.ID })
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              "Başarıyla manifesto listesi çözüldü!",
              "success"
            );
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteManifest(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
};
</script>

<style lang="scss">
.panel {
  width: 100%;
  height: 100%;

  .panel-top {
    width: 100%;
    height: 150px;
  }

  .panel-bottom {
    width: 100%;
    height: calc(100% - 150px);
  }
}
</style>