<template>
  <m-basic-form :showButtons="false">
    <div class="row">
      <div class="col-12">
        <h6>TTİ İşlemleri</h6>
      </div>
      <div class="col-12">
        <div class="col-5">
          <m-select
            :options="vehicles"
            v-model="vehicleId"
            langlabel="vehicle"
          />
        </div>

        <br />
      </div>
      <div class="col-12 mt-3">
        <b-button size="sm" @click.prevent="printAction" squared variant="info"
          >TTİ Formu Yazdır</b-button
        >
      </div>
    </div>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>

    <m-loading :msg="loadingMsg" v-if="loadingShow" />
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    api.getVehicle().then((res) => {
      if (res.data.status) {
        this.vehicles = res.data.data.rows.map((item) => {
          return {
            text: item.VEHICLEBARCODE,
            value: item.ID,
          };
        });
      }
    });
  },
  computed: {
    printerUrl() {
      return `prdr://tti_temp%20ID=${this.vehicleId}%200`;
    },
  },
  data() {
    return {
      vehicles: null,
      vehicleId: null,
    };
  },
  methods: {
    printAction() {
      if (!this.vehicleId) {
        swal("Uyarı", "Öncelikle araç seçiniz!", "error");
        return;
      }
      this.$refs.printerBtn.click();
    },
  },
};
</script>

<style></style>
