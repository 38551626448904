import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueI18n from "vue-i18n";

if (process.env.NODE_ENV === "production") {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;
import mCom from "./components/index";

mCom.install(Vue);

import "./libs/Directives";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

import Multiselect from "vue-multiselect";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

Vue.use(VueI18n);
const i18n = new VueI18n();

// register globally
Vue.component("multiselect", Multiselect);

window.intval = (n) => {
  if (n) {
    return parseInt(n) || 0;
  }
  return 0;
};

window.toNumberFromTr = (n) => {
  if (n) {
    return parseFloat(n.toString().replace(",", "."));
  } else {
    return 0;
  }
};

window.toTrFromNumber = (n) => {
  if (n) {
    return n.toString().replace(".", ",");
  }
  return 0;
};

import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
