import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: require("../views/Login").default,
  },
  {
    path: "/companylogin",
    name: "BrokerageLogin",
    component: require("../views/BrokerageLogin").default,
  },
  {
    path: "/main",
    name: "Main",
    component: require("../views/Main").default,
  },
  {
    path: "/brokeragemain",
    name: "BrokerageMain",
    component: require("../views/BrokerageMain").default,
  },

  {
    path: "/grid",
    name: "Grid",
    component: require("../views/Grid").default,
  },

  {
    path: "/welcome",
    name: "welcome",
    component: require("../views/Home").default,
  },
  //#region form
  {
    path: "/form/myaccount",
    name: "MyAccount",
    component: require("../views/Form/MyAccount").default,
  },
  {
    path: "/form/firm/:id?",
    name: "Firm",
    component: require("../views/Form/Firm").default,
  },
  {
    path: "/form/user/:id?",
    name: "User",
    component: require("../views/Form/User").default,
  },
  {
    path: "/form/country/:id?",
    name: "Country",
    component: require("../views/Form/Country").default,
  },
  {
    path: "/form/region/:id?",
    name: "Region",
    component: require("../views/Form/Region").default,
  },
  {
    path: "/form/city/:id?",
    name: "City",
    component: require("../views/Form/City").default,
  },
  {
    path: "/form/county/:id?",
    name: "County",
    component: require("../views/Form/County").default,
  },

  {
    path: "/form/town/:id?",
    name: "Town",
    component: require("../views/Form/Town").default,
  },

  {
    path: "/form/role/:id?",
    name: "Role",
    component: require("../views/Form/Role").default,
  },

  {
    path: "/form/reseller/:id?",
    name: "Reseller",
    component: require("../views/Form/Reseller").default,
  },

  {
    path: "/form/package/:id?",
    name: "Package",
    component: require("../views/Form/Package").default,
  },

  {
    path: "/form/brokerage/:id?",
    name: "Brokerage",
    component: require("../views/Form/Brokerage").default,
  },

  {
    path: "/form/brokerageagent/:id?",
    name: "BrokerageAgent",
    component: require("../views/Form/BrokerageAgent").default,
  },

  {
    path: "/form/present/:id?",
    name: "Present",
    component: require("../views/Form/Present").default,
  },

  {
    path: "/form/presentaddress/:id?",
    name: "PresentAddress",
    component: require("../views/Form/PresentAddress").default,
  },

  {
    path: "/form/cargo/:id?/:isbrokerage?/:apicargoid?",
    name: "Cargo",
    component: () => import("../views/Form/Cargo" /* webpackChunkName: 'cargo' */),
  },

  {
    path: "/form/cargobasic/:id?/:isbrokerage?/:apicargoid?",
    name: "CargoBasic",
    component: () => import("../views/Form/CargoBasic" /* webpackChunkName: 'cargo.basic' */),
  },

  {
    path: "/form/vehicleupload",
    name: "VehicleUpload",
    component: () => import("../views/Form/VehicleUpload" /* webpackChunkName: 'vehicle.upload' */),
  },

  {
    path: "/form/vehicledownload",
    name: "VehicleDownload",
    component: () => import("../views/Form/VehicleDownload" /* webpackChunkName: 'vehicle.download' */),
  },

  {
    path: "/form/apiintegration/:id?",
    name: "ApiIntegration",
    component: () => import("../views/Form/ApiIntegration" /* webpackChunkName: 'apiintegration' */),
  },

  {
    path: "/form/vehicle/:id?",
    name: "Vehicle",
    component: require("../views/Form/Vehicle").default,
  },

  {
    path: "/form/box/:id?",
    name: "Box",
    component: () => import("../views/Form/Box" /* webpackChunkName: 'box' */),
  },

  {
    path: "/form/staticprice/:id?",
    name: "StaticPrice",
    component: () => import("../views/Form/StaticPrice" /* webpackChunkName: 'static.price' */),
  },

  {
    path: "/form/returnvariable/:id?",
    name: "ReturnVariable",
    component: require("../views/Form/ReturnVariable").default,
  },
  {
    path: "/form/campaigncoupon/:id?",
    name: "CampaignCoupon",
    component: require("../views/Form/CampaignCoupon").default,
  },

  {
    path: "/form/sendingtype/:id?",
    name: "SendingType",
    component: require("../views/Form/SendingType").default,
  },
  {
    path: "/form/paytype/:id?",
    name: "PayType",
    component: require("../views/Form/PayType").default,
  },
  {
    path: "/form/cargotype/:id?",
    name: "CargoType",
    component: require("../views/Form/CargoType").default,
  },
  {
    path: "/form/contract/:id?",
    name: "Contract",
    component: require("../views/Form/Contract").default,
  },
  {
    path: "/form/lang/:id?",
    name: "Lang",
    component: require("../views/Form/Lang").default,
  },
  {
    path: "/form/langfield/:id?",
    name: "LangField",
    component: require("../views/Form/LangField").default,
  },
  {
    path: "/form/cargotrack/:id?",
    name: "CargoTrack",
    component: require("../views/Form/CargoTrack").default,
  },

  {
    path: "/form/resellertrans/:id?",
    name: "ResellerTrans",
    component: require("../views/Form/ResellerTrans").default,
  },
  {
    path: "/form/resellerclaimtrans/:id?",
    name: "ResellerClaimTrans",
    component: require("../views/Form/ResellerClaimTrans").default,
  },

  {
    path: "/form/contracttrans/:id?",
    name: "ContractTrans",
    component: require("../views/Form/ContractTrans").default,
  },
  {
    path: "/form/contractpayedtrans/:id?",
    name: "ContractPayedTrans",
    component: require("../views/Form/ContractPayedTrans").default,
  },
  {
    path: "/form/manifest/:id?/:cargoids?",
    name: "Manifest",
    component: require("../views/Form/Manifest").default,
  },

  {
    path: "/form/resellermap/:id?",
    name: "ResellerMap",
    component: require("../views/Form/ResellerMap").default,
  },
  //#endregion

  {
    path: "/list/basic/:type",
    name: "BasicList",
    component: require("../views/List/Basic").default,
  },

  {
    path: "/upload/file",
    name: "FileUpload",
    component: require("../views/Form/FileUpload").default,
  },

  {
    path: "/service/tti",
    name: "TTIService",
    component: require("../views/Form/Tti").default,
  },

  {
    path: "/integration/farmazon",
    name: "Farmazon",
    component: require("../views/Form/Farmazon").default,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
