import App from "./Base/App";
import Menu from "./Base/Menu/Menu";
import MenuItem from "./Base/Menu/MenuItem";
import SubMenu from "./Base/Menu/SubMenu";
import Tab from "./Base/Tab/Tab";
import Loading from "./Base/Loading";
import Stats from "./Base/Stats";

import Input from "./Base/Form/Input";
import InputBtn from "./Base/Form/InputBtn";
import Select from "./Base/Form/Select";
import Textarea from "./Base/Form/Textarea";
import YesNo from "./Base/Form/YesNo";
import Checkbox from "./Base/Form/Checkbox";
import ColorPicker from "./Base/Form/ColorPicker";

import BasicForm from "./Template/BasicForm";
import BasicList from "./Template/BasicList";

import GridColSettings from "./App/GridColSettings";
import Present from "./App/Present";

import SearchCargo from "./Search/Cargo";
import SearchRole from "./Search/Role";
import SearchCity from "./Search/City";
import SearchFirm from "./Search/Firm";
import SearchPackage from "./Search/Package";
import SearchVehicle from "./Search/Vehicle";
import SearchBrokerage from "./Search/Brokerage";
import SearchBrokerageagent from "./Search/Brokerageagent";
import SearchCounty from "./Search/County";
import SearchPresent from "./Search/Present";
import SearchPresentAddress from "./Search/PresentAddress";
import SearchReseller from "./Search/Reseller";
import SearchTown from "./Search/Town";
import SearchUser from "./Search/User";
import SearchReturnVariable from "./Search/ReturnVariable";
import SearchCampaignCoupon from "./Search/CampaignCoupon";
import SearchPayType from "./Search/PayType";
import SearchSendingType from "./Search/SendingType";
import SearchCargoType from "./Search/CargoType";
import SearchApiCargo from "./Search/ApiCargo";
import SearchBox from "./Search/Box";
import SearchStaticPrice from "./Search/StaticPrice";
import DailyCashbox from "./Search/DailyCashbox";
import SumCashbox from "./Search/SumCashbox";
import SumEarning from "./Search/SumEarning";
import Cashbox from "./Search/Cashbox";
import Earning from "./Search/Earning";
import SumContractTrans from "./Search/SumContractTrans";
import ContractTrans from "./Search/ContractTrans";
import CargoTransKind from "./Search/CargoTransKind";
import CargoOther from "./Search/CargoOther";

import SearchContractAccount from "./Search/ContractAccount";
import SearchResellerAccount from "./Search/ResellerAccount";

import SearchDeliveryCargo from "./Search/DeliveryCargo";
import SearchCargoPersonDelivery from "./Search/CargoPersonDelivery";
import SearchResellerCashbox from "./Search/ResellerCashbox";

import MapDraw from "./Base/Map/Draw";
import MapShow from "./Base/Map/Show";
import copy from 'copy-to-clipboard';

export default {
  install(Vue) {
    Vue.component("m-app", App);
    Vue.component("m-menu", Menu);
    Vue.component("m-menu-item", MenuItem);
    Vue.component("m-sub-menu", SubMenu);
    Vue.component("m-tab", Tab);
    Vue.component("m-loading", Loading);
    Vue.component("m-stats", Stats);

    Vue.component("m-input", Input);
    Vue.component("m-input-btn", InputBtn);
    Vue.component("m-select", Select);
    Vue.component("m-textarea", Textarea);
    Vue.component("m-yes-no", YesNo);
    Vue.component("m-checkbox", Checkbox);
    Vue.component("m-color-picker", ColorPicker);

    Vue.component("m-basic-form", BasicForm);
    Vue.component("m-basic-list", BasicList);

    Vue.component("m-present", Present);

    Vue.component("search-cargo", SearchCargo);
    Vue.component("search-role", SearchRole);
    Vue.component("search-city", SearchCity);
    Vue.component("search-firm", SearchFirm);
    Vue.component("search-package", SearchPackage);
    Vue.component("search-vehicle", SearchVehicle);
    Vue.component("search-brokerage", SearchBrokerage);
    Vue.component("search-brokerageagent", SearchBrokerageagent);
    Vue.component("search-county", SearchCounty);
    Vue.component("search-present", SearchPresent);
    Vue.component("search-presentaddress", SearchPresentAddress);
    Vue.component("search-reseller", SearchReseller);
    Vue.component("search-town", SearchTown);
    Vue.component("search-user", SearchUser);
    Vue.component("search-returnvariable", SearchReturnVariable);
    Vue.component("search-campaigncoupon", SearchCampaignCoupon);
    Vue.component("search-paytype", SearchPayType);
    Vue.component("search-sendingtype", SearchSendingType);
    Vue.component("search-cargotype", SearchCargoType);
    Vue.component("search-apicargo", SearchApiCargo);
    Vue.component("search-box", SearchBox);
    Vue.component("search-staticprice", SearchStaticPrice);
    Vue.component("search-dailycashbox", DailyCashbox);
    Vue.component("search-sumcashbox", SumCashbox);
    Vue.component("search-sumearning", SumEarning);
    Vue.component("search-cashbox", Cashbox);
    Vue.component("search-earning", Earning);
    Vue.component("search-sumcontracttrans", SumContractTrans);
    Vue.component("search-contracttrans", ContractTrans);
    Vue.component("search-cargotranskind", CargoTransKind);
    Vue.component("search-cargoother", CargoOther);

    Vue.component("search-cargodeliveried", CargoOther);
    Vue.component("search-cargoreturned", CargoOther);
    Vue.component("search-cargocancelled", CargoOther);
    Vue.component("search-cargonotdelivered", CargoOther);

    Vue.component("search-contractaccount", SearchContractAccount);
    Vue.component("search-reselleraccount", SearchResellerAccount);

    Vue.component("search-deliverycargo", SearchDeliveryCargo);
    Vue.component("search-cargopersondelivery", SearchCargoPersonDelivery);
    Vue.component("search-resellercashbox", SearchResellerCashbox);

    Vue.component("m-map-draw", MapDraw);
    Vue.component("m-map-show", MapShow);

    Vue.component("m-grid-col-settings", GridColSettings);
  },
};
