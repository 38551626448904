<template>
  <b-form-group
    label-for="input-horizontal"
    :label-cols-sm="nolabel ? 0 : 4"
    :label="nolabel ? '' : getLabel"
    label-size="sm"
    label-align="right"
  >
    <b-form-select
      v-model="vValue"
      :options="options"
      :value-field="valueField"
      :text-field="textField"
      :disabled="disabled"
      :placeholder="placeholder"
      :state="validate != null ? !validate.$invalid : null"
    ></b-form-select>
    <b-form-invalid-feedback v-if="validate != null && validate.$dirty">
      <template v-for="item in validate.$params">
        <p :key="`${item.type}`" v-if="!validate[item.type]">
          {{ getMessage(item) }}
        </p>
      </template>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  props: {
    nolabel: {
      type: Boolean,
      default: () => false,
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    value: [String, Number],
    label: {
      type: String,
      default: () => null,
    },
    langlabel: {
      type: String,
      default: () => null,
    },
    type: {
      type: String,
      default: () => "text",
    },
    options: {
      type: Array,
      default: () => [],
    },
    valueField: {
      type: String,
      default: () => "value",
    },
    textField: {
      type: String,
      default: () => "text",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    validate: {
      default: () => null,
      type: Object,
    },
  },
  computed: {
    getLabel() {
      if (this.label == null && this.langlabel != null) {
        return (
          this.$t("form." + this.langlabel) + (this.isRequired ? " *" : "")
        );
      }
      return this.label + (this.isRequired ? " *" : "");
    },
    vValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  data() {
    return {
      isRequired: false,
    };
  },
  methods: {
    getMessage(item) {
      switch (item.type) {
        case "required":
          this.isRequired = true;
          return `${this.getLabel} boş olamaz!`;
          break;
        case "email":
          return `${this.getLabel} geçersiz!`;
          break;
        case "numeric":
          return `${this.getLabel} sayısal olmalıdır!`;
          break;
        case "minLength":
          return `${this.getLabel} en az ${item.min} karakter olmalıdır!`;
          break;
        case "maxLength":
          return `${this.getLabel} en fazla ${item.max} karakter olmalıdır!`;
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  font-size: inherit !important;
}
.invalid-feedback p {
  margin-bottom: 0px;
}
</style>
