<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
  >
    <div class="row">
      <div class="col-5">
        <m-yes-no
          v-model="form.ISBROKERAGEPRESENT"
          langlabel="ismediatepresent"
        />
        <m-select
          :disabled="!form.ISBROKERAGEPRESENT"
          :options="options.brokerage"
          v-model="form.BROKERAGEID"
          langlabel="mediate"
        />
        <m-select
          :disabled="!form.ISBROKERAGEPRESENT"
          :options="options.brokerageagent"
          v-model="form.BROKERAGEAGENTID"
          langlabel="mediateagent"
        />

        <m-input v-model="form.FIRMNAME" langlabel="title" />
        <m-input v-model="form.FULLNAME" langlabel="fullname" />
        <m-input v-model="form.TAXOFFICE" langlabel="taxoffice" />
        <m-input v-model="form.IDENTITYNO" langlabel="taxno" />

        <m-input v-model="form.FIRSTNAME" langlabel="firstname" />
        <m-input v-model="form.LASTNAME" langlabel="lastname" />
        <m-input v-model="form.SECONDNAME" langlabel="secondname" />

        <m-yes-no v-model="form.ISCOMPANY" langlabel="iscompany" />
        <m-input v-model="form.BANKIBAN" langlabel="bankiban" />
        <m-input v-model="form.BANKNAME" langlabel="bankname" />
      </div>

      <div class="col-5">
        <m-input v-model="form.PHONE" langlabel="phone" />
        <m-input v-model="form.GSM" langlabel="gsm" />
        <m-input v-model="form.EMAIL" langlabel="email" />
        <m-yes-no v-model="form.ISACTIVE" langlabel="isactive" />
        <m-textarea v-model="form.DESCRIPTION" langlabel="description" />
      </div>
    </div>
  </m-basic-form>
</template>

<script>
import api from "../../api";
import swal from "sweetalert";
export default {
  mounted() {
    if (intval(this.$route.params.id) > 0) {
      this.ID = intval(this.$route.params.id);
      this.getPresent(this.ID);
    }
    this.getBrokerage();
  },
  data() {
    return {
      ID: null,
      form: {
        ISBROKERAGEPRESENT: 0,
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        EMAIL: null,
        ISACTIVE: 1,
        DESCRIPTION: null,
        ISCOMPANY: 0,
        FIRSTNAME: null,
        LASTNAME: null,
        SECONDNAME: null,
        BANKIBAN: null,
        BANKNAME: null,
      },
      loadingShow: false,
      loadingMsg: null,
      options: {
        brokerage: [],
        brokerageagent: [],
      },
    };
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getBrokerageAgentWithBrokerageId(id) {
      api
        .getBrokerageAgentWidthBrokerageId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerageagent = res.data.data.rows.map((item) => {
              return { text: item.AGENTTITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    save() {
      this.loadingMsg = this.$t("dialog.savingrecord");
      this.loadingShow = true;
      api
        .savePresent(this.form, this.ID)
        .then((res) => {
          if (res.data.status) {
            if (this.ID == res.data.data.ID) {
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullyupdatedrecord"),
                "success"
              );
            } else {
              this.ID = res.data.data.ID;
              swal(
                this.$t("dialog.success"),
                this.$t("dialog.successfullysaverecord"),
                "success"
              );
            }
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    getPresent(id) {
      this.loadingMsg = this.$t("dialog.fetchingdata");
      this.loadingShow = true;
      api
        .getPresentOne(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            const {
              ISBROKERAGEPRESENT,
              BROKERAGEID,
              BROKERAGEAGENTID,
              FIRMNAME,
              FULLNAME,
              IDENTITYNO,
              TAXOFFICE,
              PHONE,
              GSM,
              EMAIL,
              ISACTIVE,
              DESCRIPTION,

              ISCOMPANY,
              FIRSTNAME,
              LASTNAME,
              SECONDNAME,
              BANKIBAN,
              BANKNAME,
            } = res.data.data;
            this.form = {
              ISCOMPANY,
              FIRSTNAME,
              LASTNAME,
              SECONDNAME,
              BANKIBAN,
              BANKNAME,
              ISBROKERAGEPRESENT,
              BROKERAGEID,
              BROKERAGEAGENTID,
              FIRMNAME,
              FULLNAME,
              IDENTITYNO,
              TAXOFFICE,
              PHONE,
              GSM,
              EMAIL,
              ISACTIVE,
              DESCRIPTION,
            };
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    remove() {
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deletePresent(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
  },
  watch: {
    "form.BROKERAGEID"(id) {
      this.getBrokerageAgentWithBrokerageId(id);
    },
  },
};
</script>

<style></style>
