<template>
  <div class="row">
    <div class="col-12">
      <m-input
        class="search-form-field"
        v-model="search.FIRMNAME"
        nolabel
        placeholder="Firma Adı"
      ></m-input>
    </div>
  </div>
</template>

<script>
  import api from "../../api";
  export default {
    data() {
      return {
        search: {
          FIRMNAME: null,
        },
      };
    },

    methods: {
      getSearch() {
        return this.search;
      },
      clearSearch() {
        this.search = {
          FIRMNAME: null,
        };
      },
    },
  };
</script>

<style></style>
