<template>
  <b-form-group
    label-for="input-horizontal"
    label-cols-sm="4"
    :label="label"
    label-size="sm"
    label-align="right"
  >
    <b-form-checkbox-group
      v-model="vValue"
      :options="options"
      stacked
    ></b-form-checkbox-group>
  </b-form-group>
</template>

<script>
  export default {
    props: {
      value: [Array, String, Number],
      label: String,
      options: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      vValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        },
      },
    },
  };
</script>
