<template>
  <b-tabs>
    <b-tab :title="$t('search.global')" active>
      <div class="row">
        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.BARCODE"
            nolabel
            :placeholder="$t('search.barcode')"
          ></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.actiondate") }}
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input
            class="search-form-field"
            v-model="search.SAVEDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input
            class="search-form-field"
            type="date"
            v-model="search.SAVEDATE[1]"
            nolabel
          ></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.duedate") }}
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input
            class="search-form-field"
            v-model="search.DUEDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input
            class="search-form-field"
            type="date"
            v-model="search.DUEDATE[1]"
            nolabel
          ></m-input>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.present") }}
          </span>
        </div>
        <div class="col-12">
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.name')"
            v-model="search.FULLNAME"
          ></m-input>
          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.firmname')"
            v-model="search.FIRMNAME"
          ></m-input>

          <m-input
            class="search-form-field"
            nolabel
            :placeholder="$t('search.identityno')"
            v-model="search.IDENTITYNO"
          ></m-input>
        </div>

        <div class="col-12">
          {{ $t("search.deliverystatus") }}

          <m-select
            v-model="search.ISDELIVERY"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>

        <div class="col-12">
          <span class="text-center" style="display: block">
            {{ $t("search.deliverydate") }}
          </span>
        </div>
        <div class="col-6">
          {{ $t("search.startdate") }}
          <m-input
            class="search-form-field"
            v-model="search.DELIVERYDATE[0]"
            nolabel
            type="date"
          ></m-input>
        </div>
        <div class="col-6">
          {{ $t("search.enddate") }}
          <m-input
            class="search-form-field"
            type="date"
            v-model="search.DELIVERYDATE[1]"
            nolabel
          ></m-input>
        </div>

        <div class="col-6">
          {{ $t("search.cancelstatus") }}
          <m-select
            v-model="search.ISCANCEL"
            class="search-form-field"
            nolabel
            :options="options.yesNo"
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.returnstatus") }}
          <m-select
            v-model="search.ISRETURN"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.isclosed") }}
          <m-select
            v-model="search.ISCLOSED"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.approvestatus") }}
          <m-select
            v-model="search.ISAPPROVED"
            class="search-form-field"
            :options="options.approved"
            nolabel
          ></m-select>
        </div>
        <div class="col-6">
          {{ $t("search.isaccounted") }}
          <m-select
            v-model="search.ISACCOUNTED"
            class="search-form-field"
            :options="options.yesNo"
            nolabel
          ></m-select>
        </div>
      </div>
    </b-tab>
    <b-tab :title="$t('search.mediate')">
      <div class="row">
        <div class="col-12">
          {{ $t("search.mediate") }}

          <m-select
            class="search-form-field"
            v-model="search.BROKERAGEID"
            nolabel
            :options="options.brokerage"
          ></m-select>
        </div>
        <div class="col-12">
          <m-input
            class="search-form-field"
            v-model="search.INTEGRATIONBARCODE"
            nolabel
            :placeholder="$t('search.mediatebarcode')"
          ></m-input>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import api from "../../api";
export default {
  data() {
    return {
      search: {
        BARCODE: null,
        INTEGRATIONBARCODE: null,

        SAVEDATE: [null, null],
        DUEDATE: [null, null],
        DELIVERYDATE: [null, null],
        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
        BROKERAGEID: null,

        ISCANCEL: null,
        ISDELIVERY: null,
        ISRETURN: null,
        ISCLOSED: null,
        ISAPPROVED: null,
        ISACCOUNTED: null,
      },
      options: {
        reseller: [],
        yesNo: [
          { text: "Tümü", value: null },
          { text: "Evet", value: "1" },
          { text: "Hayır", value: "0" },
        ],
        approved: [
          { text: "Tümü", value: null },
          { text: "Onaylandı", value: "Onaylandı" },
          { text: "Beklemede", value: "Beklemede" },
        ],
        brokerage: [],
      },
    };
  },
  mounted() {
    this.options.yesNo[0].text = this.$t("buton.all");
    this.options.yesNo[1].text = this.$t("buton.yes");
    this.options.yesNo[2].text = this.$t("buton.no");
    this.getReseller();
    this.getBrokerage();
  },
  methods: {
    getBrokerage() {
      api
        .getBrokerage()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.brokerage = res.data.data.rows.map((item) => {
              return { text: item.BROKERAGETITLE, value: item.ID };
            });
          } else if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    getReseller() {
      api.getReseller().then((res) => {
        if (res.data.status) {
          this.options.reseller = res.data.data.rows.map((item) => {
            return {
              text: item.RESELLERNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    getSearch() {
      return this.search;
    },
    clearSearch() {
      this.search = {
        BARCODE: null,
        INTEGRATIONBARCODE: null,

        SAVEDATE: [null, null],
        DUEDATE: [null, null],

        DELIVERYDATE: [null, null],

        FIRMNAME: null,
        FULLNAME: null,
        IDENTITYNO: null,
        BROKERAGEID: null,

        ISCANCEL: null,
        ISDELIVERY: null,
        ISRETURN: null,
        ISCLOSED: null,
        ISAPPROVED: null,
        ISACCOUNTED: null,
      };
    },
  },
};
</script>

<style></style>
