<template>
  <div style="width: 100%; height: calc(100vh - 120px)">
    <a href="#" @click="clearPolygons()">Temizle</a>
    <div ref="mapDrawArea" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import config from "../../../config";
import Maps from "../../../libs/Maps";
export default {
  mounted() {
    if (config.USE_MAP_API) {
      this.gMap = new Maps();

      let intervalInitialized = null;
      let timeoutInitialized = null;
      const waitedInitializedTime = 10 * 1000;
      let waitedEnd = false;

      intervalInitialized = setInterval(() => {
        if (waitedEnd) {
          clearTimeout(timeoutInitialized);
          clearInterval(intervalInitialized);
        }
        if (this.gMap.isInitialized) {
          clearTimeout(timeoutInitialized);
          clearInterval(intervalInitialized);
          this.mapInit();
        }
      }, 1000);

      timeoutInitialized = setTimeout(() => {
        waitedEnd = true;
      }, waitedInitializedTime);
    }
  },
  data() {
    return {
      /**
       * @type Maps
       */
      gMap: null,

      polygons: [],
    };
  },
  methods: {
    mapInit() {
      this.gMap.initDrawingMap(this.$refs.mapDrawArea);
      if (this.polygons.length > 0) {
        this.gMap.setPoliygons(this.polygons);
      }
    },
    getPolygons() {
      return JSON.stringify(this.gMap.getPolygons());
    },
    setPolygons(data) {
      if (data != null && data != "" && data.length > 0)
        this.polygons = JSON.parse(data);
    },
    clearPolygons() {
      this.gMap.clearAllPolygons();
    },
  },
  watch: {
    polygons() {
      if (this.polygons.length > 0) {
        this.gMap.setPoliygons(this.polygons);
      }
    },
  },
};
</script>

<style>
</style>